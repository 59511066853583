<template>
    <div>
        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="6" md="3">
                        <b-form-group label-for="name" label="Unit Name">
                            <validation-provider #default="{ errors }" name="name" rules="">
                                <b-form-input
                                    id="name"
                                    name="name"
                                    v-model="unit.name"
                                    :state="errors.length > 0 ? false : null"
                                    size="sm"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.name">{{ serverErrors.name[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" md="2">
                        <b-form-group label-for="abbreviation" label="Abbreviation">
                            <validation-provider #default="{ errors }" name="abbreviation" rules="">
                                <b-form-input
                                    id="abbreviation"
                                    name="abbreviation"
                                    v-model="unit.abbreviation"
                                    :state="errors.length > 0 ? false : null"
                                    size="sm"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.abbreviation">{{ serverErrors.abbreviation[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" md="2">
                        <b-form-group label-for="needDescription" label="Describe?">
                            <validation-provider #default="{ errors }" name="needDescription" rules="">
                                <b-form-checkbox
                                    id="needDescription"
                                    name="needDescription"
                                    :value="true"
                                    v-model="unit.needDescription"
                                    class="custom-control-success ml-2"
                                    style="margin-top:7px;"
                                >
                                </b-form-checkbox>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.needDescription">{{serverErrors.needDescription[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" md="2" class="justify-content-start pt-1">
                        <b-button variant="primary" size="sm" @click="submit">
                            <span class="text-nowrap" v-if="unit.id == null && !saving">Save</span>
                            <span class="text-nowrap" v-else-if="unit.id != null && !saving">Save changes</span>
                            <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                            <span v-if="saving">Saving...</span>
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                :busy="isBusy"
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
            <template #cell(needDescription)="data">
                <b-badge
                    pill
                    :variant="`light-${resolveStatusVariant(data.item.needDescription)}`"
                    class="text-capitalize"
                >
                    <span v-if="data.item.needDescription" title="On registering inputs, describe packaging">Yes</span>
                    <span v-else>No</span>
                </b-badge>
            </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
    BSpinner, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import unitsStoreModule from '@/views/cromis/units/unitsStoreModule'
import useUnitsList from '@/views/cromis/units/useUnitsList'

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner, BBadge,
        ValidationObserver, ValidationProvider,
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)

        const unit = ref({
            id: null,
            name: null,
            abbreviation: null,
            needDescription: false,
        })

        const CROMIS_STORE_MODULE_NAME = 'cromis-unit'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, unitsStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            isBusy,

            // Filers
            season_id,

            // Resolvers
            resolveStatusVariant,
        } = useUnitsList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null

            unit.value = {
                id: item.id,
                name: item.name,
                abbreviation: item.abbreviation,
                needDescription: item.needDescription,
            }
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (unit.value.id === null || unit.value.id === 0)
                handleCreate()
            else
                handleUpdate(unit.value)


            unit.value = {
                id: null,
                name: null,
                abbreviation: null,
                needDescription: false,
            }
        }

        const handleCreate = async () => {
            saving.value = true
            // const valid = await isFormValid()

            // if (!valid) {
            //     saving.value = false
            //     return
            // }


            await store.dispatch('cromis-unit/create', unit.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: `Unit ${response.data.name} registered successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const handleUpdate = async (item) => {
            // saving.value = true
            // const valid = await isFormValid()

            // if (!valid) {
            //     saving.value = false
            //     return
            // }

            await store.dispatch('cromis-unit/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to unit ${response.data.name} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const remove = async (id) => {
            await store.dispatch('cromis-unit/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            unit,

            // For table
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            isBusy,

            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            invokeUpdateForm,
            submit,
            isFormValid,
            handleCreate,
            handleUpdate,
            remove,
            resolveStatusVariant,
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>